import { PaymentMethodType } from './routes/billing/payment-method/edit/components/add-payment-method.component';

/**
 * Mapper to all route-paths in this remix app
 */
export const paths = {
    root: () => '/',
    login: () => '/login',
    logout: () => '/logout',
    change_language: () => `/change-language`,
    billing: () => '/billing',
    billing_refunds: () => '/billing/refunds',
    billing_payments: () => '/billing/payments',
    billing_debitmemos: () => '/billing/debitmemos',
    billing_creditmemos: () => '/billing/creditmemos',
    billing_invoices: () => '/billing/invoices',
    billing_details_paymentmethod_edit: () => '/payment-method/edit',
    billing_details_billinginformation_edit: () => '/billing-information/edit',
    billing_download_creditmemos_pdf: (memoNumber: string) =>
        `/billing/download/creditmemos/${memoNumber}.pdf`,
    billing_download_debitmemos_pdf: (debitmemoId: string) =>
        `/billing/download/debitmemos/${debitmemoId}.pdf`,
    billing_download_invoices_pdf: (invoiceId: string) =>
        `/billing/download/invoices/${invoiceId}.pdf`,
    billing_download_paymentmethod_mandate: (paymentMethodId: string) =>
        `/billing/download/payment-method/${paymentMethodId}/mandate`,
    billing_paymentmethod_setdefault: (paymentMethodId: string) =>
        `/billing/payment-method/${paymentMethodId}/set-default`,
    billing_paymentmethod_delete: (paymentMethodId: string) =>
        `/billing/payment-method/${paymentMethodId}/delete`,
    billing_paymentpages_rsasignature: (paymentMethod: PaymentMethodType) =>
        `/billing/payment-pages/rsa-signature/${paymentMethod}`,
    subscriptions: () => '/subscriptions',
    subscription_change_quantity: (subscriptionId: string) =>
        `/subscription/${subscriptionId}/change-quantity`,
    subscription_cancel: (subscriptionId: string) =>
        `/subscription/${subscriptionId}/cancel`,
    readyaccount_add_product: (readyAccountId: string) =>
        `/readyaccount/${readyAccountId}/add-product`,
    readyaccount_available_products: (readyAccountId: string) =>
        `/readyaccount/${readyAccountId}/available-products`,
    readyaccount_add_temporary_license: (readyAccountId: string) =>
        `/readyaccount/${readyAccountId}/add-temporary-license`,
    readyaccount_cancel_temporary_license: (readyAccountId: string) =>
        `/readyaccount/${readyAccountId}/cancel-temporary-license`,
    readyaccount_cancel_all_subscriptions: (readyAccountId: string) =>
        `/readyaccount/${readyAccountId}/cancel-all-subscriptions`,
    products_temporary_base_license_product_rate_plans: () =>
        `/products/temporary-base-license-product-rate-plans`,
    products_data_retention_service_product_rate_plans: () =>
        `/products/data-retention-service-product-rate-plans`,
};
